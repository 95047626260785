import React from 'react';
import { Alert } from 'react-bootstrap';

const MessageDisplay = ({ message }) => (
  <Alert variant="warning">
    {message}
  </Alert>
);

export default MessageDisplay;
