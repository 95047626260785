import React from 'react';
import Loader from './Loader';
import Message from './Message';
import { useGetTagsWithProductsQuery } from '../slices/tagsApiSlice'; // 新しいクエリをインポート
import GalleryDisplay from './GalleryDisplay';

const TagsGallery = () => {
    const { data: tagsData, isLoading, isError, error } = useGetTagsWithProductsQuery(); // 新しいクエリを使用
    
    if (isLoading) return <Loader />; // データロード中はローダーを表示
    if (isError) return <Message variant="danger">{error?.data?.message || 'Error fetching tags and products'}</Message>; // エラーがある場合はメッセージを表示

    return (
        <>
            {tagsData && tagsData.map(tag => (
                tag.products && tag.products.length > 0 ? (
                    <section className='product-gallery' key={tag.id}>
                        <h2 className='product-gallery__title'>{tag.name}</h2>
                        <GalleryDisplay
                            type='gallery'
                            products={tag.products}
                        />
                    </section>
                ) : null
            ))}
        </>
    );
};

export default TagsGallery;