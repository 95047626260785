// src/slices/areaApiSlice.js
import { apiSlice } from './apiSlice';
import { AREAS_URL } from '../constants/constants';

export const areasApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAreas: builder.query({
            query: () => ({
                url: AREAS_URL,
            }),
            providesTags: ['Areas'],
            keepUnusedDataFor: 3600, // 1時間キャッシュ
        }),
        getAreaById: builder.query({
            query: (id) => `${AREAS_URL}/${id}`,
            providesTags: (result, error, id) => [{ type: 'Areas', id }],
        }),
        createArea: builder.mutation({
            query: (newArea) => ({
                url: AREAS_URL,
                method: 'POST',
                body: newArea,
            }),
            invalidatesTags: ['Areas'],
        }),
        updateArea: builder.mutation({
            query: ({ id, ...updatedArea }) => ({
                url: `${AREAS_URL}/${id}`,
                method: 'PUT',
                body: updatedArea,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Areas', id }],
        }),
        deleteArea: builder.mutation({
            query: (id) => ({
                url: `${AREAS_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Areas'],
        }),
    }),
});

// エンドポイントフックをエクスポート
export const { 
    useGetAreasQuery, 
    useGetAreaByIdQuery, 
    useCreateAreaMutation, 
    useUpdateAreaMutation, 
    useDeleteAreaMutation,
} = areasApiSlice;