import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CATEGORIES } from '../../constants/categories';
import Paginate from '../Paginate';

const CardDisplay = ({ productsData }) => (
    <>
        <ul className='grid-display'>
            {productsData.products.map(product => (
                <li>
                    <div className='grid-item' key={product.id} md={4} lg={3}>
                        <Link className='product__img-wrapper' to={`/product/${product.id}`}>
                            <Image src={product.image} alt={product.name} fluid />
                        </Link>
                    </div>
                    <div className='mb-2 my-2'>
                        <Link className='product__img' to={`/product/${product.id}`}>
                            <b className='product__title'>{product.name}</b>
                            <div className='product-caption'>{product.category?.name}</div>
                            <div className='product__price-wrapper'>
                                <div className='product__price'>
                                    ¥{product.price}
                                </div>
                            </div>
                        </Link>
                    </div>
                </li>
            
            ))}
        </ul>
        <Paginate
            pages={productsData.meta.pages}
            page={productsData.meta.page}
        />
    </>
);

export default CardDisplay;