import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from '../constants/constants';

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'include',
});

export const apiSlice = createApi({
    baseQuery,
    tagTypes: ['Areas', 'Balance', 'Withdrawals', 'Product', 'Order', 'User', 'Favorites', 'Brand', 'Categories', 'ShopOwner', 'Shop', 'Organization' ],
    endpoints: (builder) => ({}),
});