export const addDecimals = (num) => {
    return Math.round(num);
}

export const updateCart = (state) => {
    //Calculate items price
    state.itemsPrice = addDecimals(state.cartItems.reduce((acc, item) => acc + item.finalPrice, 0));

    // 送料の計算（全てのアイテムのinStoreがtrueの場合無料、それ以外は880円）
    state.shippingPrice = addDecimals(
        state.cartItems.every(item => item.inStore) ? 0 : 880
    );

    //Calculate tax price(10% tax)
    const taxRate = 0.10; // 10%の消費税
    state.taxPrice = addDecimals(Number(state.itemsPrice - (state.itemsPrice / ( 1 + taxRate ))));

    //Calculate total price
    state.totalPrice = (
        Number(state.itemsPrice) +
        Number(state.shippingPrice)
    );

    localStorage.setItem('cart', JSON.stringify(state));

    return state;
}