import { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useSendApprovalRequestMutation } from '../slices/organizationsApiSlice';
import { useParams } from 'react-router-dom';

const UserApprovalRequestScreen = () => {
    const { id: organizationId } = useParams();
    const [sendApprovalRequest, { isLoading }] = useSendApprovalRequestMutation();
    const dispatch = useDispatch();

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await sendApprovalRequest(organizationId).unwrap();
            toast.success('承認リクエストが送信されました');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    return (
        <Row>
            <Col md={6} className="mx-auto">
                <h2>承認リクエスト送信</h2>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='organizationId' className='my-2'>
                        <Form.Label>団体ID</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='団体のIDを入力'
                            value={organizationId}
                            readOnly
                        ></Form.Control>
                    </Form.Group>
                    <Button type='submit' variant='primary' className='my-2' disabled={isLoading}>
                        リクエスト送信
                    </Button>
                </Form>
            </Col>
        </Row>
    );
};

export default UserApprovalRequestScreen;
