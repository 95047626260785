import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGetShopsQuery } from '../slices/shopsApiSlice';
import { useGetAreasQuery } from '../slices/areasApiSlice';
import Tabs from '../components/Tabs';
import { Container, Row, Col, InputGroup, FormControl, Button, Form } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

const ShopListScreen = () => {
    const { data: shops, isError, isLoading } = useGetShopsQuery();
    const { data: areasData, isError: areasError, isLoading: areasLoading } = useGetAreasQuery();
    const [selectedArea, setSelectedArea] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');

    const tabsHandler = (value) => setSelectedArea(value);

    const areaTabs = useMemo(() => {
        if (!areasData) return [];
        return [
            { label: 'すべて', value: 'all', ariaSelected: selectedArea === 'all' },
            ...areasData
                .filter(area => area.id !== 1) // 必要であればフィルタリング
                .map(area => ({
                    label: area.name,
                    value: String(area.id), // ID を文字列として設定
                    ariaSelected: selectedArea === String(area.id),
                }))
        ];
    }, [selectedArea, areasData]);

    const filteredAndSortedShops = useMemo(() => {
        if (!shops) return []; // shops が存在しない場合にエラーチェック

        const filteredByArea = selectedArea === 'all' ? shops : shops.filter((shop) => shop.areaId === Number(selectedArea));
        const filteredByName = filteredByArea.filter((shop) => shop.name.toLowerCase().includes(searchTerm.toLowerCase()));

        return filteredByName.sort((a, b) => a.name.localeCompare(b.name));
    }, [shops, selectedArea, searchTerm]);

    const groupedShops = useMemo(() => {
        if (!filteredAndSortedShops || filteredAndSortedShops.length === 0) {
            return {};
        }
    
        return filteredAndSortedShops.reduce((groups, shop) => {
            const letter = shop.name[0].toUpperCase();
            if (!/^[A-Z]$/.test(letter)) {
                // アルファベットでない場合は「その他」に分類
                if (!groups['その他']) {
                    groups['その他'] = [];
                }
                groups['その他'].push(shop);
            } else {
                if (!groups[letter]) {
                    groups[letter] = [];
                }
                groups[letter].push(shop);
            }
            return groups;
        }, {});
    }, [filteredAndSortedShops]);

    const searchHandler = (e) => {
        setSearchTerm(e.target.value);
    };

    if (isLoading || areasLoading) return <div className="loader">Loading...</div>;
    if (isError || areasError) return <div className="error-message">Error occurred</div>;

    return (
        <Container>
            <Row className="mb-4">
                <Col>
                    <h1>ショップから探す</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>
                            <FaSearch />
                        </InputGroup.Text>
                        <FormControl
                            placeholder="ショップ名を入力"
                            aria-label="Shop's name"
                            aria-describedby="basic-addon2"
                            onChange={searchHandler}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs tabs={areaTabs} onClick={tabsHandler} selectedValue={selectedArea} />
                </Col>
            </Row>
            <nav className="shop-nav mb-4">
                {Object.keys(groupedShops).map((letter) => (
                    <li className='shop-nav-list' key={letter}>
                        <a href={`#${letter}`}>{letter}</a>
                    </li>
                ))}
            </nav>
            {Object.entries(groupedShops).map(([letter, shops]) => (
                <section className='shop-group my-2' key={letter}>
                    <h2 id={letter} className=" mb-2">{letter}</h2>
                    <div className='shop-list'>
                        {shops.map((shop) => (
                            <Link className="shop-card" to={`/shop/${shop.slug}`} key={shop.id}> {/* keyを追加 */}
                                <img className="shop-logo" alt={`Logo of ${shop.name}`} src={shop.logo} />
                                <div className="shop-name">
                                    <b>{shop.name}</b>
                                </div>
                            </Link>
                        ))}
                    </div>
                </section>
            ))}
        </Container>
    );
}

export default ShopListScreen;