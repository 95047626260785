export const PARENT_CATEGORIES = {
    1: 'トップス',
    2: 'ジャケット/アウター',
    3: 'パンツ',
    4: 'オールインワン',
    5: 'スカート',
    6: 'ワンピース',
    7: 'フォーマルスーツ/小物',
    8: 'バッグ',
    9: 'シューズ',
    10: '帽子',
    11: 'アクセサリー',
    12: 'その他',
};

export const CATEGORIES = {
    101: 'Tシャツ/カットソー',
    102: 'シャツ/ブラウス',
    103: 'ビジネスシャツ',
    104: 'ポロシャツ',
    105: 'ニット/セーター',
    106: 'ベスト',
    107: 'パーカー',
    108: 'スウェット',
    109: 'カーディガン/ボレロ',
    110: 'アンサンブル',
    111: 'ジャージ',
    112: 'タンクトップ',
    113: 'キャミソール',
    114: 'チューブトップ',
    115: 'その他トップス',

    201: 'テーラードジャケット',
    202: 'ノーカラージャケット',
    203: 'ノーカラーコート',
    204: 'レザージャケット',
    205: 'デニムジャケット',
    206: 'ライダースジャケット',
    207: 'ブルゾン',
    208: 'ミリタリージャケット',
    209: 'MA-1',
    210: 'ダウンジャケット/コート',
    211: 'ダウンベスト',
    212: 'ダッフルコート',
    213: 'モッズコート',
    214: 'ピーコート',
    215: 'ステンカラーコート',
    216: 'トレンチコート',
    217: 'チェスターコート',
    218: 'ムートンコート',
    219: 'ナイロンジャケット',
    220: 'マウンテンパーカー',
    221: 'スタジャン',
    222: 'スカジャン',
    223: 'セットアップ',
    224: 'カバーオール',
    225: 'ポンチョ',
    226: 'その他アウター',

    301: 'デニムパンツ',
    302: 'カーゴパンツ',
    303: 'チノパンツ',
    304: 'スウェットパンツ',
    305: 'スラックス',
    306: 'その他パンツ',

    401: 'サロペット/オーバーオール',
    402: 'つなぎ/オールインワン',

    501: 'スカート',
    502: 'デニムスカート',

    601: 'ワンピース',
    602: 'シャツワンピース',
    603: 'ジャンパースカート',
    604: 'チュニック',
    605: 'ドレス',
    606: 'パンツドレス',

    701: 'スーツジャケット',
    702: 'スーツベスト',
    703: 'スーツパンツ',
    704: 'スーツスカート',
    705: 'スーツセット',
    706: 'ネクタイ',
    707: '蝶ネクタイ',
    708: 'ネクタイピン',
    709: 'カフリンクス',
    710: 'ポケットチーフ',

    801: 'ショルダーバッグ',
    802: 'トートバッグ',
    803: 'バックパック/リュック',
    804: 'ボストンバッグ',
    805: 'ボディバッグ/ウエストポーチ',
    806: 'ハンドバッグ',
    807: 'クラッチバッグ',
    808: 'メッセンジャーバッグ',
    809: 'ビジネスバッグ',
    810: 'スーツケース/キャリーバッグ',
    811: 'ドラムバッグ',
    812: 'サブバッグ',
    813: 'かごバッグ',
    814: 'その他バッグ',

    901: 'スニーカー',
    902: 'サンダル',
    903: 'パンプス',
    904: 'ブーツ',
    905: 'ドレスシューズ',
    906: 'バレエシューズ',
    907: 'ローファー',
    908: 'モカシン/デッキシューズ',
    909: 'レインシューズ',
    910: 'その他シューズ',
    911: 'シューズアクセサリー',
    912: 'シューズ小物/雑貨',

    1001: 'キャップ',
    1002: 'ハット',
    1003: 'ニットキャップ/ビーニー',
    1004: 'ハンチング/ベレー帽',
    1005: 'キャスケット',
    1006: 'サンバイザー',
    1007: 'その他帽子',

    1101: 'ネックレス',
    1102: 'リング',
    1103: 'ピアス',
    1104: 'イヤリング',
    1105: 'イヤーカフ',
    1106: 'ブレスレット',
    1107: 'バングル/リストバンド',
    1108: 'アンクレット',
    1109: 'チョーカー',
    1110: 'ブローチ/コサージュ',
    1111: 'チャーム',
    1112: 'その他アクセサリー',

    1201: 'その他',
};

export const CATEGORY_MAPPING = {
    1: [101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115],
    2: [201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226],
    3: [301, 302, 303, 304, 305, 306],
    4: [401, 402],
    5: [501, 502],
    6: [601, 602, 603, 604, 605, 606],
    7: [701, 702, 703, 704, 705, 706, 707, 708, 709, 710],
    8: [801, 802, 803, 804, 805, 806, 807, 808, 809, 810, 811, 812, 813, 814],
    9: [901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 911, 912],
    10: [1001, 1002, 1003, 1004, 1005, 1006, 1007],
    11: [1101, 1102, 1103, 1104, 1105, 1106, 1107, 1108, 1109, 1110, 1111, 1112],
    12: [1201],
};

export const PARENT_CATEGORIES_EN = {
    1: 'tops',
    2: 'jackets-outerwear',
    3: 'pants',
    4: 'allinone',
    5: 'skirts',
    6: 'onepiece',
    7: 'suit',
    8: 'bags',
    9: 'shoes',
    10: 'hats',
    11: 'accessories',
    12: 'others',
};

export const CATEGORIES_EN = {
    101: 'tshirt-cutsew',
    102: 'shirt-blouse',
    103: 'business-shirt',
    104: 'polo-shirt',
    105: 'knit-sweater',
    106: 'vest',
    107: 'parka',
    108: 'sweat',
    109: 'cardigan-bolero',
    110: 'ensemble',
    111: 'jersey',
    112: 'tanktop',
    113: 'camisole',
    114: 'tube-top',
    115: 'other-tops',

    201: 'tailored-jacket',
    202: 'collarless-jacket',
    203: 'collarless-coat',
    204: 'leather-jacket',
    205: 'denim-jacket',
    206: 'riders-jacket',
    207: 'blouson',
    208: 'military-jacket',
    209: 'ma-1',
    210: 'down-jacket',
    211: 'down-vest',
    212: 'duffle-coat',
    213: 'mods-coat',
    214: 'pea-coat',
    215: 'stand-collar-coat',
    216: 'trench-coat',
    217: 'chester-coat',
    218: 'mouton-coat',
    219: 'nylon-jacket',
    220: 'mountain-parker',
    221: 'stadium-jacket',
    222: 'sukajan',
    223: 'setup',
    224: 'coverall',
    225: 'poncho',
    226: 'other-outerwear',

    301: 'denim-pants',
    302: 'cargo-pants',
    303: 'chino-pants',
    304: 'sweat-pants',
    305: 'slacks',
    306: 'other-pants',

    401: 'salopette-overall',
    402: 'connected-allinone',

    501: 'skirt',
    502: 'denim-skirt',

    601: 'onepiece',
    602: 'shirt-onepiece',
    603: 'jumper-skirt',
    604: 'tunic',
    605: 'dress',
    606: 'dressy-rompers',

    701: 'suit-jacket',
    702: 'suit-vest',
    703: 'suit-pants',
    704: 'suit-skirt',
    705: 'suit-set',
    706: 'tie',
    707: 'bow-tie',
    708: 'tie-pin',
    709: 'cufflinks',
    710: 'pocket-chief',

    801: 'shoulder-bag',
    802: 'tote-bag',
    803: 'backpack-rucksack',
    804: 'boston-bag',
    805: 'body-bag-waist-pouch',
    806: 'hand-bag',
    807: 'clutch-bag',
    808: 'messenger-bag',
    809: 'business-bag',
    810: 'suitcase-carry-bag',
    811: 'drum-bag',
    812: 'sub-bag',
    813: 'basket-bag',
    814: 'other-bags',

    901: 'sneaker',
    902: 'sandal',
    903: 'pumps',
    904: 'boots',
    905: 'dress-shoes',
    906: 'ballet-shoes',
    907: 'loafer',
    908: 'moccasin-deck-shoes',
    909: 'rain-shoes',
    910: 'other-shoes',
    911: 'shoe-accessories',
    912: 'shoe-goods-accessories',

    1001: 'cap',
    1002: 'hat',
    1003: 'knit-cap-beanie',
    1004: 'hunting-beret',
    1005: 'casquette',
    1006: 'sun-visor',
    1007: 'other-hats',

    1101: 'necklace',
    1102: 'ring',
    1103: 'pierce',
    1104: 'earring',
    1105: 'ear-cuff',
    1106: 'bracelet',
    1107: 'bangle-wristband',
    1108: 'anklet',
    1109: 'choker',
    1110: 'brooch-corsage',
    1111: 'charm',
    1112: 'other-accessories',

    1201: 'other',
};

export const PARENT_CATEGORY_TO_ID = {
    'tops': 1,
    'jackets-outerwear': 2,
    'pants': 3,
    'allinone': 4,
    'skirts': 5,
    'onepiece': 6,
    'suit': 7,
    'bags': 8,
    'shoes': 9,
    'hats': 10,
    'accessories': 11,
    'others': 12,
};

export const CATEGORY_TO_ID = {
    'tshirt-cutsew': 101,
    'shirt-blouse': 102,
    'business-shirt': 103,
    'polo-shirt': 104,
    'knit-sweater': 105,
    'vest': 106,
    'parka': 107,
    'sweat': 108,
    'cardigan-bolero': 109,
    'ensemble': 110,
    'jersey': 111,
    'tanktop': 112,
    'camisole': 113,
    'tube-top': 114,
    'other-tops': 115,
    'tailored-jacket': 201,
    'collarless-jacket': 202,
    'collarless-coat': 203,
    'leather-jacket': 204,
    'denim-jacket': 205,
    'riders-jacket': 206,
    'blouson': 207,
    'military-jacket': 208,
    'ma-1': 209,
    'down-jacket': 210,
    'down-vest': 211,
    'duffle-coat': 212,
    'mods-coat': 213,
    'pea-coat': 214,
    'stand-collar-coat': 215,
    'trench-coat': 216,
    'chester-coat': 217,
    'mouton-coat': 218,
    'nylon-jacket': 219,
    'mountain-parker': 220,
    'stadium-jacket': 221,
    'sukajan': 222,
    'setup': 223,
    'coverall': 224,
    'poncho': 225,
    'other-outerwear': 226,
    'denim-pants': 301,
    'cargo-pants': 302,
    'chino-pants': 303,
    'sweat-pants': 304,
    'slacks': 305,
    'other-pants': 306,
    'salopette-overall': 401,
    'connected-allinone': 402,
    'skirt': 501,
    'denim-skirt': 502,
    'onepiece': 601,
    'shirt-onepiece': 602,
    'jumper-skirt': 603,
    'tunic': 604,
    'dress': 605,
    'dressy-rompers': 606,
    'suit-jacket': 701,
    'suit-vest': 702,
    'suit-pants': 703,
    'suit-skirt': 704,
    'suit-set': 705,
    'tie': 706,
    'bow-tie': 707,
    'tie-pin': 708,
    'cufflinks': 709,
    'pocket-chief': 710,
    'shoulder-bag': 801,
    'tote-bag': 802,
    'backpack-rucksack': 803,
    'boston-bag': 804,
    'body-bag-waist-pouch': 805,
    'hand-bag': 806,
    'clutch-bag': 807,
    'messenger-bag': 808,
    'business-bag': 809,
    'suitcase-carry-bag': 810,
    'drum-bag': 811,
    'sub-bag': 812,
    'basket-bag': 813,
    'other-bags': 814,
    'sneaker': 901,
    'sandal': 902,
    'pumps': 903,
    'boots': 904,
    'dress-shoes': 905,
    'ballet-shoes': 906,
    'loafer': 907,
    'moccasin-deck-shoes': 908,
    'rain-shoes': 909,
    'other-shoes': 910,
    'shoe-accessories': 911,
    'shoe-goods-accessories': 912,
    'cap': 1001,
    'hat': 1002,
    'knit-cap-beanie': 1003,
    'hunting-beret': 1004,
    'casquette': 1005,
    'sun-visor': 1006,
    'other-hats': 1007,
    'necklace': 1101,
    'ring': 1102,
    'pierce': 1103,
    'earring': 1104,
    'ear-cuff': 1105,
    'bracelet': 1106,
    'bangle-wristband': 1107,
    'anklet': 1108,
    'choker': 1109,
    'brooch-corsage': 1110,
    'charm': 1111,
    'other-accessories': 1112,
    'other': 1201,
};
