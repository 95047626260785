import React from 'react';
import { Card, Button } from 'react-bootstrap';

const SuccessDisplay = ({ sessionId }) => {
  return (
    <Card className="text-center">
      <Card.Header>Subscription Success</Card.Header>
      <Card.Body>
        <Card.Title>Subscription to starter plan successful!</Card.Title>
        <Card.Text>
          Your subscription has been successfully activated.
        </Card.Text>
        <form action="/create-portal-session" method="POST">
          <input type="hidden" id="session-id" name="sessionid" value={sessionId} />
          <Button variant="primary" type="submit">Manage your billing information</Button>
        </form>
      </Card.Body>
    </Card>
  );
};

export default SuccessDisplay;
