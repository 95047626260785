// src/hoc/createResponsiveHOC.jsx
import { Suspense, lazy } from 'react';
import useDevice from '../hooks/useDevice';

/**
 * レスポンシブHOCファクトリー
 * @param {string} type - 'components' または 'screens'
 * @returns {function} - HOC
 */
const createResponsiveHOC = (type) => (name) => {
  return (props) => {
    const isMobile = useDevice();

    const path = isMobile
      ? `./${type}/Mobile/${name}`
      : `./${type}/Desktop/${name}`;

    const LazyComponent = lazy(() => import(`${path}`));

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

export const responsiveScreen = createResponsiveHOC('screens');
export const responsiveComponent = createResponsiveHOC('components');