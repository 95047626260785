import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PARENT_CATEGORIES, CATEGORY_MAPPING, CATEGORIES } from '../constants/categories';
import { GENDER, SIZE, COLOR } from '../constants/productConstants';

const DetailedSearchScreen = () => {
    const [filters, setFilters] = useState({
        parentCategory: '',
        category: '',
        gender: '',
        sizeLabel: [],
        color: [],
        priceRange: [0, 5000],
        condition: '',
    });

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setFilters(prev => ({
                ...prev,
                [name]: checked ? [...prev[name], value] : prev[name].filter(v => v !== value)
            }));
        } else if (type === 'range') {
            setFilters(prev => ({
                ...prev,
                priceRange: value.split(',').map(Number)
            }));
        } else {
            setFilters(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Filters submitted:', filters);
        // Submit filters to API or handle them as required
    };

    return (
        <div className='px-5'>
        <Link to='/' className='btn btn-light my-3'>
            戻る
        </Link>
        <h1>こだわり検索</h1>
        <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={6}>
                    <Form.Group controlId="parentCategory">
                        <Form.Label>親カテゴリ</Form.Label>
                        <Form.Select name="parentCategory" value={filters.parentCategory} onChange={handleChange}>
                            <option value="">全て</option>
                            {Object.entries(PARENT_CATEGORIES).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="category">
                        <Form.Label>カテゴリ</Form.Label>
                        <Form.Select name="category" value={filters.category} onChange={handleChange}>
                            <option value="">全て</option>
                            {filters.parentCategory && CATEGORY_MAPPING[filters.parentCategory]?.map(categoryId => (
                                <option key={categoryId} value={categoryId}>{CATEGORIES[categoryId]}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={6}>
                    <Form.Group controlId="gender">
                        <Form.Label>性別</Form.Label>
                        <Form.Select name="gender" value={filters.gender} onChange={handleChange}>
                            <option value="">全て</option>
                            {Object.entries(GENDER).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Form.Label>サイズ</Form.Label>
                    {Object.entries(SIZE).map(([key, value]) => (
                        <Form.Check 
                            type="checkbox" 
                            label={value} 
                            name="sizeLabel" 
                            value={key} 
                            checked={filters.sizeLabel.includes(key)}
                            onChange={handleChange}
                            key={key}
                        />
                    ))}
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Form.Label>色</Form.Label>
                    {Object.entries(COLOR).map(([key, value]) => (
                        <Form.Check 
                            type="checkbox" 
                            label={value} 
                            name="color" 
                            value={key} 
                            checked={filters.color.includes(key)}
                            onChange={handleChange}
                            key={key}
                        />
                    ))}
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="priceRange">
                        <Form.Label>価格範囲 (0円 - 5000円)</Form.Label>
                        <Form.Range 
                            name="priceRange" 
                            min="0" 
                            max="5000" 
                            value={filters.priceRange.join(',')}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Button type="submit" variant="primary">検索</Button>
        </Form>
        </div>
    );
};

export default DetailedSearchScreen;