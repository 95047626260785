const GoogleMapEmbed = ({ embedSrc }) => {
    return (
        <div>
            {embedSrc ? (
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src={embedSrc} width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            ) : null}
        </div>
    );
};

export default GoogleMapEmbed;
