import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSubscriptionPlansQuery } from '../slices/subscriptionsApiSlice';
import { useCreateCheckoutSessionMutation } from '../slices/stripeApiSlice';

const SubscriptionScreen = () => {
    const navigate = useNavigate();
    const { data: plans, isLoading, isError, error } = useGetSubscriptionPlansQuery();
    const [createCheckoutSession, { isLoadingCheckout }] = useCreateCheckoutSessionMutation();

    // ローディング中のUI表示
    if (isLoading) return <div>Loading...</div>;
    // エラーがあった場合のUI表示
    if (isError) return <div>Error: {error.message}</div>;

    const handleSelectPlan = (id) => {
        // サブスクリプション選択処理（例: サーバーに保存）
        navigate(`/subscription/${id}/join`); // 次の画面へのナビゲート
    };

    return (
        <>
            <Row className="justify-content-md-center">
                <h1 className="text-center my-4">サブスクで古着を、もっと楽しもう</h1>
                {plans.map((plan) => (
                    <Col md={4} key={plan.id} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>{plan.name}</Card.Title>
                                <Card.Text>
                                    月額: ¥{plan.price}<br />
                                    {plan.itemPriceLimit} 円以下の服を、{plan.maxItems}着まで無料でレンタル可能
                                </Card.Text>
                                <Button variant="primary" onClick={() => handleSelectPlan(plan.id)}>詳細</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default SubscriptionScreen;