import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Container } from 'react-bootstrap';
import { useGetSubscriptionPlanByIdQuery } from '../slices/subscriptionsApiSlice';
import PlanDisplay from '../components/PlanDisplay';
import SuccessDisplay from '../components/SuccessDisplay';
import MessageDisplay from '../components/MessageDisplay';
import Loader from '../components/Loader';

const SubscriptionCheckoutScreen = () => {
  const {id} = useParams();
  const { data: plan, isError, isLoading } = useGetSubscriptionPlanByIdQuery(id);
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('sessionid'));
    }
    if (query.get('canceled')) {
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, []);

  if (isLoading) return <Loader />;
  if (isError) return 
  <Container>
    <Alert variant='danger'>データの取得中にエラーが生じました。サポートまでお問い合わせください</Alert>
  </Container>;
  if (!success && message === '') {
    return <PlanDisplay plan={plan} />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <MessageDisplay message={message} />;
  }
};

export default SubscriptionCheckoutScreen;
