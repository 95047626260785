import { useEffect } from "react";
import { Link, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, ListGroup, Image, Card, Container, } from "react-bootstrap";
import { toast } from 'react-toastify';
import CheckoutSteps from '../components/CheckoutSteps';
import Message from '../components/Message';
import Loader from '../components/Loader';
import CartItems from "../components/CartItems";
import { useCreateOrderMutation } from '../slices/ordersApiSlice';
import { updateUsedItems } from "../slices/authSlice";
import { clearCartItems } from '../slices/cartSlice';

const PlaceOrderScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);

    const [createOrder, { isLoading, error }] = useCreateOrderMutation();


    useEffect(() => {
        if (!cart.shippingAddress) {
            navigate('/shipping');
        } else if (!cart.paymentMethod) {
            navigate('/payment');
        }
    }, [cart.paymentMethod, cart.shippingAddress, navigate])

    const placeOrderHandler = async () => {
        try {
            const rentalItems = cart.cartItems.filter(item => item.serviceOption === 'rent');
            const purchaseItems = cart.cartItems.filter(item => item.serviceOption === 'buy');

            const res = await createOrder({
                orderItems: purchaseItems.concat(rentalItems),
                shippingAddress: cart.shippingAddress,
                paymentMethod: cart.paymentMethod,
                itemsPrice: cart.itemsPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
            }).unwrap();
            dispatch(clearCartItems());
            (res.usedItems && dispatch(updateUsedItems(res.usedItems)));
            navigate(`/order/${res.order.id}`);
        } catch (err) {
            toast.error(err);
        }
    };
    

    return (
        <Container>
            <CheckoutSteps step1 step2 step3 step4 />
            <Row>
                <Col md={8}>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <h2>お届け先</h2>
                            <p>
                                <strong>住所: </strong>
                                {'〒'}{cart.shippingAddress.postalCode} {cart.shippingAddress.country} {cart.shippingAddress.city} {cart.shippingAddress.address}
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2>お支払い方法</h2>
                            <strong>お支払い方法: </strong>
                            {cart.paymentMethod}
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2>商品</h2>
                            {cart.cartItems.length === 0 ? (
                                <Message>カートが空です</Message>
                            ) : (
                                <CartItems cartItems={cart.cartItems} />
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <h2>ご注文内容（税込み）</h2>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>商品:</Col>
                                    <Col>
                                        {cart.itemsPrice}円
                                    </Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>送料:</Col>
                                    <Col>
                                        {cart.shippingPrice}円
                                    </Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>合計:</Col>
                                    <Col>
                                        {cart.totalPrice}円
                                    </Col>
                                </Row>
                            </ListGroup.Item>

                            { error && (
                                <ListGroup.Item>
                                    <Message variant='danger'>{error.data.message}</Message>
                                </ListGroup.Item>
                            )}

                            <ListGroup.Item>
                                <Button
                                    type="button"
                                    className="btn-block"
                                    disabled={cart.cartItems.length === 0}
                                    onClick={placeOrderHandler}
                                >
                                    お支払い画面へ
                                </Button>
                                {isLoading && <Loader />}
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PlaceOrderScreen;