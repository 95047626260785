import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetShopBySlugQuery } from '../slices/shopsApiSlice';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import { useParams, useLocation } from 'react-router-dom';
import { Row, Col, Spinner, Alert, Container } from 'react-bootstrap';
import FavoriteButton from '../components/FavoriteButton';
import ShopHomeTop from '../components/ShopHomeTop';
import ShopNavigationBar from '../components/ShopNavigationBar';
import ShopLatestItems from '../components/ShopLatestItems';
import ShopProductsDisplay from '../components/ShopProductsDisplay';
import ShopSnsLinks from '../components/ShopSnsLinks';
import GoogleMapsEmbed from '../components/GoogleMapsEmbed';

const ShopHomeScreen = () => {
    const { userInfo } = useSelector((state) => state.auth);

    const slug = useParams().slug;
    const location = useLocation();

    const [content, setContent] = useState('home');
    useEffect(() => {
        if (location.pathname.includes('about')) {
            setContent('about');
        } else if (location.pathname.includes('contact')) {
            setContent('contact');
        } else {
            setContent('home');
        }
    }, [location]);

    const { data: shop, isError, isLoading } = useGetShopBySlugQuery(slug);
    const shopId = shop?.id;
    const {
        data: productsData,
        isError: productsError,
        isLoading: productsLoading
    } = useGetProductsQuery({
        shopId,
    });

    // 背景色の状態を追加
    const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // デフォルトの背景色
    const [coverImage, setCoverImage] = useState(''); // ショップカバー画像
    const [homeTop, setHomeTop] = useState(shop?.homeTop || 1); // デフォルトのホームトップレイアウト
    const [logoFontSize, setLogoFontSize] = useState('2rem'); // デフォルトのフォントサイズ
    const [logoFont, setLogoFont] = useState('Arial'); // デフォルトのフォント
    const [logoFontWeight, setLogoFontWeight] = useState('bold'); // デフォルトのフォントの太さ
    const [logoFontColor, setLogoFontColor] = useState('#000000');

    const shadowOptions = [
        { name: 'なし', value: '0px 0px 0px' },
        { name: '軽い', value: '1px 1px 2px' },
        { name: '普通', value: '2px 2px 4px' },
        { name: '強い', value: '4px 4px 8px' },
    ];

    const [shadowOption, setShadowOption] = useState(shadowOptions[0].value); // デフォルトは「なし」
    const [shadowColor, setShadowColor] = useState('#000000'); 

    const [nav, setNav] =useState(shop?.nav || 1);
    const [navFontSize, setNavFontSize] = useState('1.7rem');
    const [navFontColor, setNavFontColor] = useState('#000000');
    const [navFontFamily, setNavFontFamily] = useState('Arial');
    const [navFontWeight, setNavFontWeight] = useState('bold');

    const [latestItems, setLatestItems] = useState('gallery');

    const [displayStyle, setDisplayStyle] = useState('card');

    const [iconSize, setIconSize] = useState(40);
    const [snsUrls, setSnsUrls] = useState({
        instagram: '',
        facebook: '',
        twitter: '',
        line: '',
    });

    useEffect(() => {
        if (shop) {
            setCoverImage(shop.coverImage || '');
            setBackgroundColor(shop.customizations?.backgroundColor || '#ffffff');
            setHomeTop(shop.customizations?.homeTopLayout || 1);
            setLogoFontSize(shop.customizations?.logo?.fontSize || '2rem');
            setLogoFont(shop.customizations?.logo?.fontFamily || 'Arial');
            setLogoFontWeight(shop.customizations?.logo?.fontWeight || 'bold');
            setLogoFontColor(shop.customizations?.logo?.color || '#000000');
            setShadowOption(shop.customizations?.logo?.shadow?.option || '0px 0px 0px');
            setShadowColor(shop.customizations?.logo?.shadow?.color || '#000000');
            setNav(shop.customizations?.nav?.layout || 1);
            setNavFontSize(shop.customizations?.nav?.fontSize || '1rem');
            setNavFontColor(shop.customizations?.nav?.fontColor || '#000000');
            setNavFontFamily(shop.customizations?.nav?.fontFamily || 'Arial');
            setNavFontWeight(shop.customizations?.nav?.fontWeight || 'normal');
            setLatestItems(shop.customizations?.latestItemsLayout || 'gallery');
            setDisplayStyle(shop.customizations?.displayStyle || 'card');
            setIconSize(shop.customizations?.snsIconSize || 40);
            setSnsUrls({
                instagram: shop.customizations?.snsLinks?.instagram ,
                facebook: shop.customizations?.snsLinks?.facebook ,
                twitter: shop.customizations?.snsLinks?.twitter ,
                line: shop.customizations?.snsLinks?.line ,
            });
        }
    }, [shop]);

    // SNSのデフォルトカラー
    const iconColors = {
        instagram: '#E1306C',
        facebook: '#3b5998',
        twitter: '#1DA1F2',
        line: '#00b900'
    };

    // 背景色を適用するスタイル
    const backgroundStyle = {
        backgroundColor: backgroundColor,
    };

    const logoStyle = {
        fontSize: logoFontSize, // フォントサイズ
        fontFamily: logoFont,   // フォントファミリー
        fontWeight: logoFontWeight, // フォントの太さ
        color: logoFontColor,
        textShadow: `${shadowOption} ${shadowColor}`,
    };

    const latestProducts = useMemo(() => {
        // データのロードが完了しているかどうかをここでチェック
        if (productsData && productsData.products && productsData.products.length > 0) {
          // 配列のコピーを作成し、そのコピーをソートする
          return [...productsData.products]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 10);
        }
        return [];
    }, [productsData]);

    if (isLoading || productsLoading) return <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
    </Spinner>;

    if (isError || productsError) return <Alert variant="danger">
        Error loading shop or products.
    </Alert>;

    return (
        <div fluid style={backgroundStyle}>
            <Container>
                <Row className='my-4'>
                    <Col>
                        <ShopHomeTop shop={shop} homeTop={homeTop} logoStyle={logoStyle} coverImage={coverImage} />
                    </Col>
                </Row>

                <Row className='py-3'>
                    <Col>
                        <ShopNavigationBar
                            nav={nav}
                            navFontSize={navFontSize}
                            navFontColor={navFontColor}
                            navFontFamily={navFontFamily}
                            navFontWeight={navFontWeight}
                            slug={slug}
                        />
                    </Col>
                </Row>

                { userInfo &&
                    <Row className="d-flex justify-content-end">
                        <Col md='auto'>
                            <FavoriteButton itemId={shopId} isProduct={false} />
                        </Col>
                    </Row>
                }

                {content === 'home' ? (
                    <>
                        {latestItems !== 'none' && (
                            <Row className='mb-3'>
                                <Col>
                                    <h2 className="mb-4">最新商品</h2>
                                    <ShopLatestItems latestItems={latestItems} latestProducts={latestProducts} />
                                </Col>
                            </Row>
                        )}
                    
                        <Row className="mb-3">
                            <Col>
                                <h2 className="mb-4">商品一覧</h2>
                                <ShopProductsDisplay productsData={productsData} displayStyle={displayStyle} />
                            </Col>
                        </Row>
                    </>
                ) : content === 'about' ? (<>
                    <Row className="mb-2">
                        <h2 className="mb-3">About</h2>
                    </Row>
                    <Row className="mb-3">
                        <Col className=''>
                            <p><strong>お店について</strong>: {shop.description}</p>
                            <p><strong>営業時間</strong>: {shop.openingHours}</p>
                            <p><strong>住所</strong>: 〒{shop.postalCode} {shop.prefecture} {shop.city} {shop.streetAddress} {shop.buildingName} </p>
                        </Col>
                        <Col>
                            <GoogleMapsEmbed
                                embedSrc={shop.googleMapsEmbedSrc}
                            />
                        </Col>
                    </Row>
                </>) : (
                    <Row className="mb-3">
                        <Col>
                            <h2 className="mb-4">Contact</h2>
                            <p><strong>電話番号</strong>: {shop.phoneNumber}</p>
                            <p><strong>メールアドレス</strong>: {shop.email}</p>
                        </Col>
                    </Row>
                )}

                <Row className="justify-content-center mb-3">
                    <ShopSnsLinks snsUrls={snsUrls} iconSize={iconSize} iconColors={iconColors} />
                </Row>
            </Container>
        </div>
    );
};

export default ShopHomeScreen;