import React from 'react';

const genderFilters = [
    { label: 'すべて', value: 'all' },
    { label: 'メンズ', value: 'men', icon: 'man_2' },
    { label: 'レディース', value: 'women', icon: 'woman_2' },
    { label: 'キッズ', value: 'kids', icon: 'boy' },
];

const GenderFilter = ({ selectedGender, onClick }) => {
    return (
        <div role='tablist' className='gender-filter'>
            {genderFilters.map((gender) => (
                <a
                    key={gender.value}
                    onClick={() => onClick(gender.value)}
                    className={gender.value}
                    role="tab"
                    aria-selected={selectedGender === gender.value}
                    aria-label={gender.label}
                >
                    {gender.icon && <span className="material-symbols-outlined">{gender.icon}</span>}
                    <div>{gender.label}</div>
                </a>
            ))}
        </div>
    );
}

export default GenderFilter;
