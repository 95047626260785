import { useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { 
    useAddFavoriteProductMutation,
    useDeleteFavoriteProductMutation, 
    useAddFavoriteShopMutation,
    useDeleteFavoriteShopMutation
} from '../slices/favoritesApiSlice';
import { Row } from 'react-bootstrap';

const FavoriteButton = ({ itemId, isProduct }) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const { userInfo } = useSelector((state) => state.auth);

    const [addFavoriteProduct] = useAddFavoriteProductMutation();
    const [deleteFavoriteProduct] = useDeleteFavoriteProductMutation();
    const [addFavoriteShop] = useAddFavoriteShopMutation();
    const [deleteFavoriteShop] = useDeleteFavoriteShopMutation();

    const handleFavoriteToggle = async () => {
        if (!userInfo) {
            toast.error('ログインが必要です');
            return;
        }

        try {
            if (isFavorite) {
                if (isProduct) {
                    await deleteFavoriteProduct(itemId).unwrap();
                } else {
                    await deleteFavoriteShop(itemId).unwrap();
                }
                toast.error('お気に入りから削除しました');
                setIsFavorite(false);
            } else {
                if (isProduct) {
                    const res = await addFavoriteProduct(itemId).unwrap();
                    if (res.alreadyFavorite) {
                        toast.info('すでにお気に入りに登録されています');
                        setIsFavorite(true);
                    } else {
                        toast.success('お気に入りに追加しました');
                        setIsFavorite(true);
                    }
                } else {
                    const res = await addFavoriteShop(itemId).unwrap();
                    if (res.alreadyFavorite) {
                        toast.info('すでにお気に入りに追加されています');
                        setIsFavorite(true);
                    } else {
                        toast.success('お気に入りに追加しました');
                        setIsFavorite(true);
                    }
                }
            }
        } catch (error) {
            toast.error('操作に失敗しました');
        }
    };

    return (
            <button className="favorite-button" onClick={handleFavoriteToggle}>
                <FaHeart 
                    color={isFavorite ? 'red' : '#d1d1d1'}
                    size={24}
                />
                <span className='ms-2'>
                    <strong>
                        {isFavorite ? 'お気に入りに追加済み' 
                            : isProduct ? 'お気に入りに追加'
                            : 'お気に入りショップに追加'
                        }</strong>
                </span>
            </button>
    );
};

export default FavoriteButton;