const Tabs = ({ tabs, onClick }) => {
    return (
        <div className='tabs'>
            <div className='tab-list' role='tablist'>
                {tabs.map((tab) => (
                    <a
                        key={tab.value}
                        onClick={() => onClick(tab.value)} // タブのvalueを引数としてonClickに渡す
                        className='tab-item'
                        role='tab'
                        aria-selected={tab.ariaSelected} // ariaSelected関数を実行して結果を適用
                    >
                        {tab.label}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Tabs