import { apiSlice } from './apiSlice';
import { ORDERS_URL } from '../constants/constants';

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (order) => ({
                url: ORDERS_URL,
                method: 'POST',
                body: {...order},
            }),
        }),
        getOrderDetails: builder.query({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        getOrderShippingAddress: builder.query({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/shipping`,
            }),
            keepUnusedDataFor: 5,
        }),
        payOrder: builder.mutation({
            query: ({ orderId, details }) => ({
                url: `${ORDERS_URL}/${orderId}/pay`,
                method: 'PUT',
                body: { ...details },
            }),
        }),
        getMyOrders: builder.query({
            query: () => ({
                url: `${ORDERS_URL}/mine`,
            }),
            keepUnusedDataFor: 60,
        }),
        getMyShopOrders: builder.query({
            query: () => ({
                url: `${ORDERS_URL}/myshop`,
            }),
            keepUnusedDataFor: 5,
        }),
        getOrders: builder.query({
            query: () => ({
                url: `${ORDERS_URL}`,
            }),
            keepUnusedDataFor: 5,
        }),
        deliverOrder: builder.mutation({
            query: (data) => ({
                url: `${ORDERS_URL}/${data.orderId}/deliver`,
                method: 'PUT',
                body: {purchaseItemIds: data.purchaseItemIds, rentalItemIds: data.rentalItemIds},
            }),
            keepUnusedDataFor: 5,
        }),
        returnRentalProduct: builder.mutation({
            query: (id) => ({
                url: `${ORDERS_URL}/${id}/return`,
                method: 'PUT',
            }),
        }),
        purchaseRentalProduct: builder.mutation({
            query: (rentalItemId) => ({
                url: `${ORDERS_URL}/rentals/${rentalItemId}/purchase`,
                method: 'POST'
            }),
        }),
        extendRental: builder.mutation({
            query: (data) => ({
                url: `${ORDERS_URL}/rentals/${data.rentalItemId}/extend`,
                method: 'POST',
                body: {
                    extensionPeriod: data.extensionPeriod,
                }
            }),
        }),
        cancelOrderItems: builder.mutation({
            query: (data) => ({
                url: `${ORDERS_URL}/${data.orderId}/cancel`,
                method: 'PUT',
                body: {
                    purchaseItemIds: data.purchaseItemIds,
                    rentalItemIds: data.rentalItemIds,
                    cancellationReason: data.cancellationReason,
                },
            }),
        }),
        finalPaymentCheck: builder.mutation({
            query: (data) => ({
                url: `${ORDERS_URL}/${data.orderId}/final-payment-check`,
                method: 'PUT',
                body: {paymentIntentId: data.paymentIntentId, totalPrice: data.totalPrice},
            }),
        }),
        paymentFailed: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/payment-failed`,
                method: 'PUT',
            }),
        }),
    }),
});

export const { 
    useCreateOrderMutation, 
    useGetOrderDetailsQuery, 
    useGetOrderShippingAddressQuery,
    usePayOrderMutation, 
    useGetMyOrdersQuery,
    useGetMyShopOrdersQuery,
    useGetOrdersQuery,
    useDeliverOrderMutation,
    useReturnRentalProductMutation,
    usePurchaseRentalProductMutation,
    useExtendRentalMutation,
    useCancelOrderItemsMutation,
    useFinalPaymentCheckMutation,
    usePaymentFailedMutation,
}  = ordersApiSlice;