import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from './store';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/bootstrap.custom.css';
// import './assets/styles/index.css';
import './assets/scss/main.scss'; 
import App from './App';
import reportWebVitals from './reportWebVitals';
import PrivateRoute from './components/PrivateRoute';
import HomeScreen from './screens/HomeScreen';
import SearchScreen from './screens/SearchScreen';
import DetailedSearchScreen from './screens/DetailedSearchScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import EmailVerifyScreen from './screens/EmailVerifyScreen';
import SubscriptionScreen from './screens/SubscriptionScreen';
import SubscriptionCheckoutScreen from './screens/SubscriptionCheckoutScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import ProfileScreen from './screens/ProfileScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import FavoriteScreen from './screens/FavoriteScreen';
import UserApprovalRequestScreen from './screens/UserApprovalRequestScreen';
import NotificationScreen from './screens/NotificationScreen';

import ShopListScreen from './screens/ShopListScreen';
import ShopHomeScreen from './screens/ShopHomeScreen';

import SubscriptionSuccessScreen from './screens/SubscriptionSuccessScreen';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51OaA80HQ4620qDCA5uLgRH5kjNSFFh7StObsB36wZ4qyta8IafMd7OF5a1H6tSx2q3hVHBm69QAu8g6KFUKMKIGV00jneuZ889");


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path='/search' element={<SearchScreen />} />
      <Route path='/detail' element={<DetailedSearchScreen />} />
      <Route path='/category' element={<SearchScreen />} />
      <Route path='/category/:parentCategory' element={<SearchScreen />} />
      <Route path='/category/:parentCategory/:category' element={<SearchScreen />} />
      <Route path='/product/:id' element={<ProductScreen />} />
      <Route path='/cart' element={<CartScreen />} />
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />
      <Route path='/verify-email' element={<EmailVerifyScreen />} />
      <Route path='/subscriptions' element={<SubscriptionScreen />} />
      <Route path='/subscription/:id/join' element={<SubscriptionCheckoutScreen />} />
      <Route path='/shops' element={<ShopListScreen />} />
      <Route path='/shop/:slug' element={<ShopHomeScreen />} />
      <Route path='/shop/:slug/about' element={<ShopHomeScreen />} />
      <Route path='/shop/:slug/contact' element={<ShopHomeScreen />} />
      <Route path='/notifications' element={<NotificationScreen />} />

      <Route  path='' element={<PrivateRoute />}>
        <Route  path='/shipping' element={<ShippingScreen />} />
        <Route  path='/payment' element={<PaymentScreen />} />
        <Route  path='/placeorder' element={<PlaceOrderScreen />} />
        <Route path='/order/:id' element={
          <Elements stripe={stripePromise}>
            <OrderScreen />
          </Elements>
        } />
        <Route  path='/profile' element={<ProfileScreen />} />
        <Route  path='/orderhistory' element={<OrderHistoryScreen />} />
        <Route  path='/favorites' element={<FavoriteScreen />} />
        <Route path='/user-approval-request/:id' element={<UserApprovalRequestScreen />} />
        <Route path='/subscription-success' element={<SubscriptionSuccessScreen />} />
      </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();