import { ORGANIZATIONS_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const organizationsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        organizationLogin: builder.mutation({
            query: (data) => ({
                url: `${ORGANIZATIONS_URL}/login`,
                method: 'POST',
                body: data,
            }),
        }),
        registerOrganization: builder.mutation({
            query: (data) => ({
                url: `${ORGANIZATIONS_URL}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Organization'],
        }),
        registerOrganizationByAdmin: builder.mutation({
            query: (data) => ({
                url: `${ORGANIZATIONS_URL}/admin`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Organization'],
        }),
        getOrganizations: builder.query({
            query: () => `${ORGANIZATIONS_URL}`,
            providesTags: ['Organization'],
        }),
        getOrganizationById: builder.query({
            query: (id) => `${ORGANIZATIONS_URL}/${id}`,
            providesTags: ['Organization'],
        }),
        getMyOrganization: builder.query({
            query: () => `${ORGANIZATIONS_URL}/profile`,
            providesTags: ['Organization'],
        }),
        verifyCurrentOrganizationPassword: builder.mutation({
            query: (data) => ({
                url: `${ORGANIZATIONS_URL}/verify-password`,
                method: 'POST',
                body: data,
            }),
        }),
        updateOrganizationProfile: builder.mutation({
            query: (data) => ({
                url: `${ORGANIZATIONS_URL}/profile`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Organization'],
        }),
        updateOrganizationByAdmin: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `${ORGANIZATIONS_URL}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Organization'],
        }),
        deleteOrganization: builder.mutation({
            query: (id) => ({
                url: `${ORGANIZATIONS_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Organization'],
        }),
        addOrganizationMember: builder.mutation({
            query: (data) => ({
                url: `${ORGANIZATIONS_URL}/members`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Organization'],
        }),
        removeOrganizationMember: builder.mutation({
            query: (data) => ({
                url: `${ORGANIZATIONS_URL}/members`,
                method: 'DELETE',
                body: data,
            }),
            invalidatesTags: ['Organization'],
        }),
        getOrganizationMembers: builder.query({
            query: () => `${ORGANIZATIONS_URL}/members`,
            providesTags: ['Organization'],
        }),
        sendApprovalRequest: builder.mutation({
            query: (id) => ({
                url: `${ORGANIZATIONS_URL}/${id}/approval-requests`,
                method: 'POST',
            }),
            invalidatesTags: ['Organization'],
        }),
        handleApprovalRequest: builder.mutation({
            query: (data) => ({
                url: `${ORGANIZATIONS_URL}/approval-requests`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Organization'],
        }),
    }),
});

export const {
    useOrganizationLoginMutation,
    useRegisterOrganizationMutation,
    useRegisterOrganizationByAdminMutation,
    useGetOrganizationsQuery,
    useGetOrganizationByIdQuery,
    useGetMyOrganizationQuery,
    useVerifyCurrentOrganizationPasswordMutation,
    useUpdateOrganizationProfileMutation,
    useUpdateOrganizationByAdminMutation,
    useDeleteOrganizationMutation,
    useAddOrganizationMemberMutation,
    useRemoveOrganizationMemberMutation,
    useGetOrganizationMembersQuery,
    useSendApprovalRequestMutation,
    useHandleApprovalRequestMutation,
} = organizationsApiSlice;
