import { FAVORITES_URL } from "../constants/constants";
import { apiSlice } from "./apiSlice";

export const favoritesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMyFavorites: builder.query({
            query: () => `${FAVORITES_URL}`,
            providesTags: ["Favorites"],
        }),
        addFavoriteProduct: builder.mutation({
            query: (productId) => ({
                url: `${FAVORITES_URL}/product/${productId}`,
                method: 'POST',
            }),
            invalidatesTags: ["Favorites"],
        }),
        deleteFavoriteProduct: builder.mutation({
            query: (productId) => ({
                url: `${FAVORITES_URL}/product/${productId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Favorites"],
        }),
        addFavoriteShop: builder.mutation({
            query: (shopId) => ({
                url: `${FAVORITES_URL}/shop/${shopId}`,
                method: 'POST',
            }),
            invalidatesTags: ["Favorites"],
        }),
        deleteFavoriteShop: builder.mutation({
            query: (shopId) => ({
                url: `${FAVORITES_URL}/shop/${shopId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Favorites"],
        }),
    }),
});

export const {
    useGetMyFavoritesQuery,
    useAddFavoriteProductMutation,
    useDeleteFavoriteProductMutation,
    useAddFavoriteShopMutation,
    useDeleteFavoriteShopMutation,
} = favoritesApiSlice;
