import React from 'react';
import HomeNavSidebar from '../../components/HomeNavSidebar';
import Meta from '../../components/Meta';
import TagsGallery from '../../components/TagsGallery';

const HomeScreen = () => {
  return (
    <div className='bg-f8f8f8'>
      <Meta />
      <div className='home-wrapper'>
        <div className='home-left'>
            <HomeNavSidebar />
        </div>

        <div className='home-right'>
            <TagsGallery />
        </div>
      </div>
    </div>
  )
}

export default HomeScreen