import { useSelector } from 'react-redux';
import { useGetNotificationsQuery } from '../slices/notificationsApiSlice';
import { ListGroup, Badge, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';

const NotificationScreen = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const { data: notifications, isLoading, error } = useGetNotificationsQuery({ role: userInfo.role });

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <Message variant='danger'>{error?.data?.message || error.message}</Message>;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}`;
    };

    return (
        <Container>
            <h1>通知</h1>
            <ListGroup variant='flush'>
                {notifications && notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <ListGroup.Item key={notification.id}>
                            {!notification.read && <span className="notification-unread-dot">●</span>}
                            <span>{`${formatDate(notification.createdAt)}：${notification.message}`}</span>
                        </ListGroup.Item>
                    ))
                ) : (
                    <Message>通知がありません</Message>
                )}
            </ListGroup>
        </Container>
    );
};

export default NotificationScreen;
