import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import Loader from '../components/Loader';
import { useLoginMutation } from '../slices/usersApiSlice';
import { setCredentials } from "../slices/authSlice";
import { toast } from 'react-toastify';

const LoginScreen = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login, { isLoading }] = useLoginMutation();

    const { userInfo } = useSelector((state) => state.auth);

    const {search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || '/';
    const redirectShop = sp.get('redirect') || '/shop/orderlist';

    useEffect(() => {
        if (userInfo && userInfo.role === 'user') {
            navigate(redirect);
        } else if (userInfo && userInfo.role === 'shop') {
            navigate(redirectShop);
        } else if (userInfo && userInfo.role === 'admin') {
            navigate(redirect);
        } else if (userInfo && userInfo.role === 'organization') {
            navigate(redirect);
        }
    }, [userInfo, redirect, navigate]);

    const submitHandler = async (e) => {
        e.preventDefault()
        try {
            const res = await login({ email, password }).unwrap();
            dispatch(setCredentials({...res, }));
            navigate(redirect);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    }
  return (
    <FormContainer>
        <h1>Sign In</h1>

        <Form onSubmit={submitHandler}>
            <Form.Group controlId="email" className="my-3">
                <Form.Label>メールアドレス</Form.Label>
                <Form.Control   
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                >
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="password" className="my-3">
                <Form.Label>パスワード</Form.Label>
                <Form.Control   
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                >
                </Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="mt-2" disabled={ isLoading }>
                ログイン
            </Button>

            { isLoading && <Loader /> }
        </Form>

        <Row className="py-3">
            <Col>
                初めての方は{' '}
                <Link to={ redirect ? `/register?redirect=${redirect}` : '/register' }>
                    こちら
                </Link>
                {' '}からアカウントを作成してください
            </Col>
        </Row>
    </FormContainer>
  )
}

export default LoginScreen