import moment from 'moment';

export const formatDate = (dateString, includeTime = true ) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
    const formattedDate = new Intl.DateTimeFormat('ja-JP', options).format(new Date(dateString));
  
    const [date, time] = formattedDate.split(' ');
    const [year, month, day] = date.split('/');
    return includeTime ? `${year}年${month}月${day}日 ${time}` : `${year}年${month}月${day}日`;
};

export const calculateDaysLeft = (endDate) => {
    const today = moment();
    const end = moment(endDate);
    return end.diff(today, 'days');
};

export const formatDateRange = (startDate, endDate) => {
    const options = { month: 'numeric', day: 'numeric' };

    const start = new Date(startDate);
    const end = new Date(endDate);

    const startFormatted = new Intl.DateTimeFormat('ja-JP', options).format(start);
    const endFormatted = new Intl.DateTimeFormat('ja-JP', options).format(end);

    return `${startFormatted}〜${endFormatted}`;
};

export const formatDateRange2 = (startDate, endDate) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const start = new Date(startDate);
    const end = new Date(endDate);

    const startFormatted = new Intl.DateTimeFormat('ja-JP', options).format(start);
    const endFormatted = new Intl.DateTimeFormat('ja-JP', options).format(end);

    return `${startFormatted}〜${endFormatted}`;
};
