import { apiSlice } from './apiSlice';
import { TAGS_URL } from '../constants/constants';

export const tagsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTags: builder.query({
            query: () => `${TAGS_URL}`,
            providesTags: ['Tags'],
        }),
        getTagsWithProducts: builder.query({
            query: () => `${TAGS_URL}/products`,
            providesTags: ['Tags'],
        }),
        getTagById: builder.query({
            query: (id) => `${TAGS_URL}/${id}`,
            providesTags: ['Tags'],
        }),
        getTagsByProductId: builder.query({
            query: (productId) => `${TAGS_URL}/product/${productId}`,
            providesTags: ['Tags'],
        }),
        createTag: builder.mutation({
            query: (body) => ({
                url: TAGS_URL,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Tags'],
        }),
        addProductsToTag: builder.mutation({
            query: ({ id, products }) => ({
                url: `${TAGS_URL}/${id}/addproducts`,
                method: 'PUT',
                body: { products },
            }),
            invalidatesTags: ['Tags'],
        }),
        removeProductsFromTag: builder.mutation({
            query: ({ id, products }) => ({
                url: `${TAGS_URL}/${id}/removeproducts`,
                method: 'PUT',
                body: { products },
            }),
            invalidatesTags: ['Tags'],
        }),
        updateTag: builder.mutation({
            query: ({ id, name }) => ({
                url: `${TAGS_URL}/${id}`,
                method: 'PUT',
                body: { name },
            }),
            invalidatesTags: ['Tags']
        }),
        deleteTag: builder.mutation({
            query: (id) => ({
                url: `${TAGS_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Tags'],
        }),
    }),
});

export const {
    useGetTagsQuery,
    useGetTagsWithProductsQuery,
    useGetTagByIdQuery,
    useGetTagsByProductIdQuery,
    useCreateTagMutation,
    useAddProductsToTagMutation,
    useRemoveProductsFromTagMutation,
    useUpdateTagMutation,
    useDeleteTagMutation,
} = tagsApiSlice;
