import useDevice from "../hooks/useDevice";
import SearchScreenPC from "./PC/SearchScreen";
import SearchScreenSP from "./SP/SearchScreen";

const SearchScreen = () => {
    const isMobile = useDevice();
    
    return isMobile ? <SearchScreenSP /> : <SearchScreenPC />;
}

export default SearchScreen;