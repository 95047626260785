import React, { useMemo, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { GENDER_TO_ID } from '../../constants/productConstants';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import HomeNavSidebar from '../../components/HomeNavSidebar';
import Meta from '../../components/Meta';
import GenderFilter from '../../components/GenderFilter';
import Tabs from '../../components/Tabs';
import SearchResults from '../../components/SearchResults';
import { useGetProductsQuery } from '../../slices/productsApiSlice';
import { useGetAreasQuery } from '../../slices/areasApiSlice';

const SearchScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { parentCategory, category } = useParams();


    const query = new URLSearchParams(location.search);
    const keyword = query.get('keyword') || '';
    const pageNumber = query.get('page') || 1;
    const selectedGender = query.get('gender') || 'all';
    const selectedArea = query.get('area') || 'all';

    const { data: areasData, isLoading: areasLoading, error: areasError } = useGetAreasQuery();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pageNumber, location.pathname]); // 依存配列にページ番号を含める

    // useGetProductsQueryの引数に反映
    const queryParams = {
        keyword,
        pageNumber,
        ...(parentCategory && { categorySlug: category || parentCategory }), 
        ...(selectedGender !== 'all' && { gender: GENDER_TO_ID[selectedGender] }),
        ...(selectedArea !== 'all' && { area: selectedArea }),
    };

    const { data: productsData, isLoading: productsLoading, error: productsError } = useGetProductsQuery(queryParams);

    const areaTabs = useMemo(() => {
        if (!areasData) return [];
        return [
            { label: 'すべて', value: 'all', ariaSelected: selectedArea === 'all' },
            ...areasData
                .filter(area => area.id !== 1) // 'その他' を除外（必要に応じてキーを調整）
                .map(area => ({
                    label: area.name,
                    value: String(area.id), // ID を文字列として設定
                    ariaSelected: selectedArea === String(area.id),
                }))
        ];
    }, [selectedArea, areasData]);

    const handleFilterChange = (type, value) => {
        if (value === 'all') {
            query.delete(type); // 'all'の場合、クエリパラメータを削除
        } else {
            query.set(type, value);
        }
        navigate(`${location.pathname}?${query.toString()}`);
    };

    if (productsLoading) return <Loader />;
    if (productsError) return <Message variant='danger'>商品データの取得に失敗しました</Message>;
    if (areasError) return <Message variant='danger'>エリアデータの取得に失敗しました</Message>;

    return (
        <>
        <Meta />
            <Link to='/' className='btn btn-light mb-4'>
            Go Back
            </Link> 
        <div className='home-wrapper'>
            <div className='home-right'>
                <div className='search-heading'>
                    <div className='flex'>
                        <h1>
                            {
                                keyword ||
                                (category &&
                                productsData?.data &&
                                productsData?.data[0]?.category &&
                                productsData.data[0].category.name) ||
                                category ||
                                parentCategory ||
                                (selectedArea !== 'all' && `「${areasData.find(a => a.id === Number(selectedArea))?.name}」の商品`) ||
                                `すべての商品`
                            }
                        </h1>
                        <div className='search-count'>
                            <span>{productsData.meta.count}</span>件
                        </div>
                        <div className='search-page'>
                            {/* <span class="material-symbols-outlined">
                                apparel
                            </span> */}
                            <p>
                                {pageNumber}/{productsData.meta.pages} ページ
                            </p>
                        </div>
                    </div>
                    <GenderFilter
                        selectedGender={selectedGender}
                        onClick={(value) => handleFilterChange('gender', value)}
                    />
                    <Tabs
                        tabs={areaTabs}
                        onClick={(value) => handleFilterChange('area', value)}
                    />
                </div>
                <SearchResults
                    productsData={productsData}
                    keyword={keyword}
                />
            </div>
        </div>
        </>
    )
}

export default SearchScreen