import useDevice from "../hooks/useDevice";
import HomeScreenPC from "./PC/HomeScreen";
import HomeScreenSP from "./SP/HomeScreen";

const HomeScreen = () => {
    const isMobile = useDevice();
    
    return isMobile ? <HomeScreenSP /> : <HomeScreenPC />;
}

export default HomeScreen;