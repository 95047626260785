import { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetShopByIdQuery } from '../slices/shopsApiSlice';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Loader from './Loader';
import Message from './Message';
import { formatDateRange2 } from '../utils/dateUtils';
import { usePurchaseRentalProductMutation, useExtendRentalMutation } from '../slices/ordersApiSlice';
import { toast } from 'react-toastify';

const ShopInfoModal = ({ show, shopId, startDate, endDate, daysLeft, orderId, rentalItemId, productPrice, onHide }) => {
    const navigate = useNavigate();
    const { data: shop, error, isLoading, refetch } = useGetShopByIdQuery(shopId, {
        skip: !show, // モーダルが表示されるときだけクエリを実行
    });

    const [purchaseRentalProduct, { isLoading: isPurchasing }] = usePurchaseRentalProductMutation();
    const [extendRental, { isLoading: isExtending }] = useExtendRentalMutation();
    
    // モーダルの状態を分ける
    const [showExtendConfirmModal, setShowExtendConfirmModal] = useState(false);
    const [showPurchaseConfirmModal, setShowPurchaseConfirmModal] = useState(false);
    const [selectedExtensionPeriod, setSelectedExtensionPeriod] = useState('3days');

    useEffect(() => {
        if (show) {
            refetch(); // モーダルが表示されたときにデータを再取得
        }
    }, [show, refetch]);

    const handlePurchase = async () => {
        try {
            const res = await purchaseRentalProduct(rentalItemId).unwrap();
            navigate(`/order/${res.orderId}`);
        } catch (err) {
            console.error(err);
            toast.error(err?.data?.message || '購入に失敗しました。');
        }
    }

    const handleConfirmPurchase = () => {
        setShowPurchaseConfirmModal(true);
    };

    const handlePurchaseConfirmClose = () => {
        setShowPurchaseConfirmModal(false);
    };

    const handleExtendRental = async () => {
        if (!rentalItemId) {
            toast.error('レンタルアイテムが指定されていません。');
            return;
        }

        try {
            const res = await extendRental({ rentalItemId, extensionPeriod: selectedExtensionPeriod }).unwrap();
            toast.success('レンタルの延長が完了しました。');
            onHide(); // モーダルを閉じる
            setShowExtendConfirmModal(false);
            // 必要に応じてページをリロードまたはデータを更新
        } catch (err) {
            console.error(err);
            toast.error(err?.data?.message || 'レンタル延長に失敗しました。');
        }
    };

    const handleExtendConfirmClose = () => {
        setShowExtendConfirmModal(false);
    };

    const discountedPrice = productPrice * 0.9;

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {daysLeft !== null && daysLeft !== undefined ? (
                            daysLeft >= 0 ? (
                                <>
                                    <small>返却期限:</small> <strong>あと{daysLeft}日</strong>
                                </>
                            ) : (
                                <>
                                    <small>返却期限:</small> <strong>期限切れ ({Math.abs(daysLeft)}日前)</strong>
                                </>
                            )
                        ) : isLoading ? (
                            <Loader />
                        ) : error ? (
                            <Message variant="danger">{error?.data?.message || error.error}</Message>
                        ) : shop ? (
                            <>
                                <small>店名:</small> <strong>{shop.name}</strong>
                            </>
                        ) : (
                            <>情報がありません</>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant="danger">{error?.data?.message || error.error}</Message>
                    ) : shop ? (
                        <div>
                            <p><strong>店名:</strong> {shop.name}</p>
                            <p><strong>住所:</strong> 〒{shop.postalCode} {shop.prefecture} {shop.city} {shop.streetAddress} {shop.buildingName}</p>
                            {shop.googleMapsLink && (
                                <p>
                                    <strong>地図: </strong> 
                                    <a 
                                        href={shop.googleMapsLink} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        style={{ color: '#007bff', textDecoration: 'underline', fontWeight: 'bold' }}
                                    >
                                        <FaMapMarkerAlt/> Googleマップで見る
                                    </a>
                                </p>
                            )}

                            {startDate && endDate && (
                                <p><strong>レンタル期間:</strong> {formatDateRange2(startDate, endDate)}</p>
                            )}

                            {/* レンタル延長ボタンと購入ボタンの表示 */}
                            {orderId && rentalItemId && (
                                <>
                                    <Button 
                                        variant="warning" 
                                        onClick={() => setShowExtendConfirmModal(true)} 
                                        disabled={isExtending}
                                        className="me-2" // Bootstrap 5のマージン
                                    >
                                        レンタルを延長する
                                    </Button>
                                    <Button 
                                        variant="success" 
                                        onClick={handleConfirmPurchase} 
                                        disabled={isPurchasing}
                                    >
                                        10%オフで購入
                                    </Button>
                                </>
                            )}
                        </div>
                    ) : (
                        <p>情報がありません</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        閉じる
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* レンタル延長確認モーダル */}
            {rentalItemId && (
                <Modal show={showExtendConfirmModal} onHide={handleExtendConfirmClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>レンタル延長の確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="extensionPeriod">
                                <Form.Label>延長期間を選択してください</Form.Label>
                                <Form.Control 
                                    as="select" 
                                    value={selectedExtensionPeriod} 
                                    onChange={(e) => setSelectedExtensionPeriod(e.target.value)}
                                    disabled={isExtending}
                                >
                                    <option value="3days">3日間 (商品価格の10%)</option>
                                    <option value="1week">1週間 (商品価格の20%)</option>
                                </Form.Control>
                            </Form.Group>
                        </Form>
                        <p className="mt-3">
                            延長料金: {selectedExtensionPeriod === '3days' ? '10%' : '20%'} ({selectedExtensionPeriod === '3days' ? '3日間' : '1週間'})
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleExtendConfirmClose} disabled={isExtending}>
                            キャンセル
                        </Button>
                        <Button variant="primary" onClick={handleExtendRental} disabled={isExtending}>
                            {isExtending ? '延長中...' : '延長する'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* 購入確認モーダル */}
            {productPrice && (
                <Modal show={showPurchaseConfirmModal} onHide={handlePurchaseConfirmClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>購入確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>元の金額: {productPrice.toLocaleString()}円</p>
                        <p>10%オフの金額: {discountedPrice.toLocaleString()}円</p>
                        <p>この商品を購入しますか？</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handlePurchaseConfirmClose}>
                            キャンセル
                        </Button>
                        <Button variant="success" onClick={handlePurchase} disabled={isPurchasing}>
                            購入
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );

};

export default ShopInfoModal;
