// export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '';
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PRODUCTS_URL = '/products';
export const ADMINS_URL = '/admins';
export const USERS_URL = '/users';
export const SHOP_OWNERS_URL = '/shop-owners';
export const SHOPS_URL = '/shops';
export const AREAS_URL = '/areas';
export const ORGANIZATIONS_URL = '/organizations';
export const ORDERS_URL = '/orders';
export const TAGS_URL = '/tags';
export const CATEGORIES_URL = '/categories';
export const BRANDS_URL = '/brands';
export const FAVORITES_URL = '/favorites';
export const NOTIFICATIONS_URL = '/notifications';
export const PAYPAL_URL = '/config/paypal';
export const UPLOAD_URL = '/upload';
export const STRIPE_URL = '/stripe';
export const BALANCE_URL = '/balance';
export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51OaA80HQ4620qDCA5uLgRH5kjNSFFh7StObsB36wZ4qyta8IafMd7OF5a1H6tSx2q3hVHBm69QAu8g6KFUKMKIGV00jneuZ889';

export const MAX_TAGS = 2;