import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const PasswordModal = ({ show, handleClose, handleSubmitPassword, loadingVerifyPassword, loadingUpdateProfile }) => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(false);

    const resetPasswordFields = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setIsCurrentPasswordValid(false);
        handleClose();
    };

    const verifyPasswordHandler = async (e) => {
        e.preventDefault();
        try {
            await handleSubmitPassword(currentPassword);
            setIsCurrentPasswordValid(true);
        } catch (err) {
            toast.error('現在のパスワードが正しくありません');
        }
    };

    const submitPasswordHandler = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            toast.error('新しいパスワードが一致しません');
        } else {
            try {
                await handleSubmitPassword(null, newPassword);
                resetPasswordFields();
                toast.success('パスワードが更新されました');
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    return (
        <>
            <Modal show={show && !isCurrentPasswordValid} onHide={resetPasswordFields}>
                <Modal.Header closeButton>
                    <Modal.Title>現在のパスワードを入力してください</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={verifyPasswordHandler}>
                        <Form.Group controlId="currentPassword" className="my-2">
                            <Form.Label>現在のパスワード</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="現在のパスワードを入力してください"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={loadingVerifyPassword}
                            className="mt-3"
                        >
                            {loadingVerifyPassword ? <Loader /> : '確認する'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={isCurrentPasswordValid} onHide={resetPasswordFields}>
                <Modal.Header closeButton>
                    <Modal.Title>新しいパスワードを入力してください</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitPasswordHandler}>
                        <Form.Group controlId="newPassword" className="my-2">
                            <Form.Label>新しいパスワード</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="新しいパスワードを入力してください"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="confirmNewPassword" className="my-2">
                            <Form.Label>新しいパスワード（確認用）</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="新しいパスワードを確認してください"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button
                            type="submit"
                            variant="primary"
                            className="mt-3"
                        >
                            {loadingUpdateProfile ? <Loader /> : 'アップデート'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PasswordModal;
