import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            state.userInfo = action.payload;
            localStorage.setItem('userInfo', JSON.stringify(action.payload));
        },
        updateUsedItems: (state, action) => {
            if (state.userInfo && state.userInfo.subscription) {
              // usedItems のみを更新
              state.userInfo.subscription.usedItems = action.payload;
              // 更新された userInfo を localStorage に保存
              localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
            }
        },
        updateProfile: (state, action) => {
            if (state.userInfo) {
                const { name, email } = action.payload;
                if (name) state.userInfo.name = name;
                if (email) state.userInfo.email = email;
                localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
            }
        },
        logout: (state, action) => {
            state.userInfo = null;
            localStorage.clear();
        }
    }
});

export const { setCredentials, updateUsedItems, updateProfile, logout } = authSlice.actions;

export default authSlice.reducer; // exportの時は単数系、作るときは複数系



// Redux の基本的な概念は、アプリケーションの全ての状態は一つの大きなオブジェクト（ストア）に保存されるというものです。
// reducer は、アプリケーションの状態（ステート）を変更する唯一の方法です。

// アクション: アクションは、何かが起きたことを示すオブジェクトです。通常、type プロパティ（アクションの種類を表す）と、必要に応じて他のデータ「payload（ここでは新しいユーザー情報）など」を含みます。
// ディスパッチ: アクションはディスパッチ（送信）され、Redux ストアに到達します。
// Reducer: ストアはアクションを reducer 関数に渡します。reducer は現在の状態とアクションを受け取り、必要な状態の変更を行い、新しい状態を返します。


// Reducer という名前は、関数型プログラミングの用語から来ています。このコンセプトは、多くの値を取り、それらを単一の値に「減らす（reduce）」関数を指します。
// Redux のコンテキストでは、reducer は現在の状態とアクションを引数として受け取り、新しい状態を返します。つまり、多くの可能性（現在の状態とアクション）を新しい状態に「減らす（reduce）」という考え方です。



// "Slice"という名前は、Reduxの状態（State）を「スライス（Slice）」として分割する考え方に由来しています。
// Reduxの全体的な状態は、一つの大きなオブジェクトとして管理されますが、この大きな状態を複数の小さな部分に分けることで、各部分をより管理しやすくするというアイデアが基盤にあります。