import { apiSlice } from './apiSlice';
import { STRIPE_URL } from '../constants/constants';

export const stripeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        stripePayment: builder.mutation({
            query: (order) => ({
                url: `${STRIPE_URL}/create-payment-intent`,
                method: 'POST',
                body: {
                    orderId: order.id,
                    taxPrice: order.taxPrice,
                    shippingPrice: order.shippingPrice,
                    totalPrice: order.totalPrice,
                },
            }),
        }),
        createCheckoutSession: builder.mutation({
            query: (data) => ({
                url: `${STRIPE_URL}/create-checkout-session`,
                method: 'POST',
                body: { subscriptionId: data.subscriptionId },
            }),
        }),
        createPortalSession: builder.mutation({
            query: () => ({
                url: `${STRIPE_URL}/create-portal-session`,
                method: 'POST',
            }),
        }),
    }),
});

export const { 
    useStripePaymentMutation,
    useCreateCheckoutSessionMutation,
    useCreatePortalSessionMutation,
} = stripeApiSlice;