import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useCreateCheckoutSessionMutation } from '../slices/stripeApiSlice';

const PlanDisplay = ({ plan }) => {
  const [createCheckoutSession, { isLoading }] = useCreateCheckoutSessionMutation();

  const handleCheckout = async () => {
    try {
      // サーバーにプランIDを渡してチェックアウトセッションを作成
      const { url } = await createCheckoutSession({ subscriptionId: plan.id }).unwrap();
      // セッションIDに基づいてStripeのチェックアウトページにリダイレクト
      window.location.href = url;
    } catch (error) {
      console.error('Failed to create checkout session:', error);
    }
  };

  return (
    <Card className="text-center">
      <Card.Header>選択したプラン</Card.Header>
      <Card.Body>
        <Card.Title>{plan.name}</Card.Title>
        <Card.Text>
          ¥{plan.price} / month
        </Card.Text>
        <Button variant="primary" onClick={handleCheckout} disabled={isLoading}>
          Checkout
        </Button>
      </Card.Body>
    </Card>
  );
};

export default PlanDisplay;
