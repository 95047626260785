import Product from './Product';
import Paginate from './Paginate';

const SearchResults = ({ productsData }) => {
    if (!productsData || productsData.length === 0) {
        return <alert variant='info'>該当する商品はありません</alert>;
    }
    return (
        <>
            <ul className='product-list'>
                {productsData.products.map((product) => (
                    <Product
                        type="standard"
                        product={product}
                    />
                )) ?? []}
            </ul>
            {productsData.meta && productsData.meta.pages > 1 && (
                <Paginate
                    pages={productsData.meta.pages}
                    page={productsData.meta.page}
                />
            )}
        </>
    );
};

export default SearchResults;
