import { SHOPS_URL, UPLOAD_URL } from "../constants/constants";
import { apiSlice } from "./apiSlice";

export const shopsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        shopLogin: builder.mutation({
            query: (data) => ({
                url: `${SHOPS_URL}/auth`,
                method: 'POST',
                body: data,
            }),
        }),
        createReview: builder.mutation({
            query: ({ shopId, ...data }) => ({
                url: `${SHOPS_URL}/${shopId}/reviews`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Shop"],
        }),
        registerShop: builder.mutation({
            query: (data) => ({
                url: `${SHOPS_URL}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["Shop"],
        }),
        getShops: builder.query({
            query: () => `${SHOPS_URL}`,
            providesTags: ["Shop"],
        }),
        getShopById: builder.query({
            query: (id) => `${SHOPS_URL}/${id}`,
            providesTags: ["Shop"],
        }),
        getShopBySlug: builder.query({
            query: (slug) => `${SHOPS_URL}/slug/${slug}`,
            providesTags: ["Shop"],
        }),
        getMyShop: builder.query({
            query: () => `${SHOPS_URL}/mine`,
            providesTags: ["Shop"],
        }),
        verifyCurrentShopPassword: builder.mutation({
            query: (data) => ({
                url: `${SHOPS_URL}/verify-password`,
                method: 'POST',
                body: data,
            }),
        }),
        updateShopProfile: builder.mutation({
            query: (data) => ({
                url: `${SHOPS_URL}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ["Shop"],
        }),
        updateShop: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `${SHOPS_URL}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ["Shop"],
        }),
        deleteShop: builder.mutation({
            query: (id) => ({
                url: `${SHOPS_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Shop"],
        }),
        uploadLogo: builder.mutation({
            query: (formData) => ({
                url: `${UPLOAD_URL}/logo`,
                method: "POST",
                body: formData,
            }),
        }),
        uploadShopCover: builder.mutation({
            query: (formData) => ({
                url: `${UPLOAD_URL}/shopcover`,
                method: "POST",
                body: formData,
            }),
        }),
    }),
});

export const { 
    useShopLoginMutation,
    useCreateReviewMutation,
    useRegisterShopMutation,
    useGetShopsQuery,
    useGetShopByIdQuery,
    useGetShopBySlugQuery,
    useGetMyShopQuery,
    useVerifyCurrentShopPasswordMutation,
    useUpdateShopProfileMutation,
    useUpdateShopMutation,
    useDeleteShopMutation,
    useUploadLogoMutation,
    useUploadShopCoverMutation,
} = shopsApiSlice;
