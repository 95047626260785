import { Link } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";

const Paginate = ({ pages, page }) => {
  const location = useLocation();

  // URLSearchParams オブジェクトを使用して、現在のクエリパラメータを管理
  const searchParams = new URLSearchParams(location.search);
  
  return pages > 1 && (
    <Pagination>
        {[...Array(pages).keys()].map(x => {
            // 新しいページ番号を設定
            searchParams.set('page', x + 1);

            return (
                <LinkContainer 
                    key={x + 1} 
                    to={{
                        search: `?${searchParams.toString()}`, // `search` フィールドにクエリ文字列を指定
                    }}
                >
                    <Pagination.Item
                        key={ x + 1}
                        active={x + 1 === Number(page)}
                    >
                        {x + 1}
                    </Pagination.Item>
                </LinkContainer>
            );
        })}
    </Pagination>
  );
};

export default Paginate;
