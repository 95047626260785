import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Container, Spinner } from 'react-bootstrap';
import { useVerifyEmailMutation } from '../slices/usersApiSlice';
import { setCredentials } from "../slices/authSlice";
import { toast } from 'react-toastify';

const EmailVerifyScreen = () => {
    const location = useLocation();
    const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userInfo } = useSelector((state) => state.auth);

    // クエリパラメータから token と userId を取得
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const userId = searchParams.get('userId');

    useEffect(() => {
        const verifyUserEmail = async () => {
            try {
                const res = await verifyEmail({ userId, token }).unwrap();
                dispatch(setCredentials({ ...res }));
                navigate('/');
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        };

        verifyUserEmail();
    }, [token, userId, verifyEmail, dispatch, navigate]);

    useEffect(() => {
        if (userInfo) {
            navigate('/');
        }
    }, [userInfo,  navigate]);

    return (
        <Container className="mt-5">
            {isLoading ? (
                <Spinner animation="border" variant="primary" />
            ) : userInfo ? (
                <Alert variant="success">
                    メールアドレスが正常に確認されました、ホーム画面に移動します。
                </Alert>
            ) : (
                <Alert variant="danger">
                    メールアドレスの確認に失敗しました。もう一度お試しください。
                </Alert>
            )}
        </Container>
    );
};

export default EmailVerifyScreen;