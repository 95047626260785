import React from 'react';
import CardDisplay from './Displays/CardDisplay';
import GridDisplay from './Displays/GridDisplay';
import SearchResults from './SearchResults';
import MosaicTileDisplay from './Displays/MosaicTileDisplay';
import MagazineStyleDisplay from './Displays/MagazineStyleDisplay';
import Product from './Product';

const ShopProductsDisplay = ({ displayStyle, productsData }) => {
    switch(displayStyle) {
        case 'card':
            return <CardDisplay productsData={productsData} />;
        case 'grid':
            return <GridDisplay productsData={productsData} />;
        case 'list': 
            return (
                <ul className='product-list product-list--shop'>
                    {productsData.products.map((product) => (
                        <Product type='standard' product={product} />
                    ))}
                </ul>
            );
        case 'mosaic':
            return <MosaicTileDisplay productsData={productsData} />;
        case 'magazine':
            return <MagazineStyleDisplay productsData={productsData} />;
        default:
            return null;
    }
};

export default React.memo(ShopProductsDisplay);
