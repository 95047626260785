import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Card, Modal, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PasswordModal from "../components/PasswordModal";
import Loader from "../components/Loader";
import { useVerifyCurrentPasswordMutation, useUpdateProfileMutation } from "../slices/usersApiSlice";
import { setCredentials } from '../slices/authSlice';
import { useCreatePortalSessionMutation } from "../slices/stripeApiSlice";

const ProfileScreen = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.auth);

    const [verifyCurrentPassword, { isLoading: loadingVerifyPassword }] = useVerifyCurrentPasswordMutation();
    const [updateProfile, { isLoading: loadingUpdateProfile } ] = useUpdateProfileMutation();

    const [createPortalSession, { isLoading: loadingPortalSession }] = useCreatePortalSessionMutation();

    useEffect(() => {
        if (userInfo) {
            setName(userInfo.name);
            setEmail(userInfo.email);
        }
    }, [userInfo, userInfo.name, userInfo.email]);

    const submitProfileHandler = async (e) => {
        e.preventDefault();
        try {
            const res = await updateProfile({
                name, 
                email 
            }).unwrap();
            dispatch(setCredentials(res));
            toast.success('プロフィールが更新されました');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const handleSubmitPassword = async (currentPassword, newPassword) => {
        if (currentPassword) {
            await verifyCurrentPassword({ password: currentPassword }).unwrap();
        } else if (newPassword) {
            const res = await updateProfile({ password: newPassword }).unwrap();
            dispatch(setCredentials(res));
        }
    };
    const handlePortalSession = async () => {
        try {
            const { url } = await createPortalSession().unwrap();
            window.location.href = url;
        } catch (err) {
            toast.error('ポータルセッションの作成に失敗しました');
        }
    };
    const visitSubscriptionPage = () => {
        navigate('/subscriptions');
    }

    return (
        <Container>
            <Row className="mt-4">
                <Col md={4} className="me-4">
                    <h2>プロフィール</h2>

                    <Form onSubmit={submitProfileHandler}>
                        <Form.Group controlId="name" className="my-2">
                            <Form.Label>名前</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="Enter name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="email" className="my-2">
                            <Form.Label>メールアドレス</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>

                        <Button
                            type="submit"
                            variant="primary"
                            className="my-2 mb-4"
                        >
                            更新
                        </Button>
                        { loadingUpdateProfile && <Loader />}
                    </Form>

                    <h2 className="mt-1">パスワード</h2>
                    
                    <Button
                        variant="primary"
                        className="my-2"
                        onClick={() => setShowPasswordModal(true)}
                    >
                        パスワードを変更する
                    </Button>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Header as="h2">サブスクリプション</Card.Header>
                        <Card.Body>
                            {userInfo.subscription ? (
                                <div>
                                    <p><strong>プラン: </strong> {userInfo.subscription?.name}</p>
                                    <p><strong>アイテムの上限料金: </strong> ¥{userInfo.subscription?.itemPriceLimit}</p>
                                    <p><strong>借りている着数: </strong> {userInfo.subscription?.usedItems} / {userInfo.subscription?.maxItems}</p>
                                    <Button
                                        variant="primary"
                                        className="my-2"
                                        onClick={handlePortalSession}
                                        disabled={loadingPortalSession}
                                    >
                                        {loadingPortalSession ? <Loader /> : 'プランを変更する'}
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <p>
                                        <strong>サブスクに加入して、もっとお得に古着を楽しみましょう！</strong>
                                    </p>
                                    <Button
                                        variant="primary"
                                        className="my-2"
                                        onClick={visitSubscriptionPage}
                                    >
                                        プランを見る
                                    </Button>
                                </div>
                                
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <PasswordModal 
                    show={showPasswordModal}
                    handleClose={() => setShowPasswordModal(false)}
                    handleSubmitPassword={handleSubmitPassword}
                    loadingVerifyPassword={loadingVerifyPassword}
                    loadingUpdateProfile={loadingUpdateProfile}
                />
            </Row>
        </Container>
    )
}

export default ProfileScreen;