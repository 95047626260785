import { Suspense, lazy } from "react";
import useDevice from "../hooks/useDevice";
const MobileCartItems = lazy(() => import('./SP/CartItems'));
const DesktopCartItems = lazy(() => import('./PC/CartItems'));

const CartItems = ({ cartItems, removeFromCart }) => {
    const isMobile = useDevice();
    
    // モバイルまたはデスクトップのコンポーネントを条件分岐で返す
    const SelectedCartItems = isMobile ? MobileCartItems : DesktopCartItems;
  
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <SelectedCartItems
          cartItems={cartItems}
          removeFromCart={removeFromCart}
        />
      </Suspense>
    );
  }

export default CartItems;