import React from 'react';
import GalleryDisplay from './GalleryDisplay';
import ProductCarousel from './ProductCarousel';
import SearchResults from './SearchResults';

const ShopLatestItems = ({ latestItems, latestProducts }) => {
    switch(latestItems) {
        case 'gallery':
            return <GalleryDisplay type='gallery' products={latestProducts} />;
        case 'carousel':
            return <ProductCarousel products={latestProducts} />;
        case 'list':
            return <SearchResults productsData={{ products: latestProducts }} />;
        default:
            return null;
    }
};

export default React.memo(ShopLatestItems);
