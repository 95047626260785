import React from 'react';
import { Image } from 'react-bootstrap';

const ShopHomeTop = ({ shop, homeTop, logoStyle, coverImage }) => {
    if (!shop) return null;

    switch(homeTop) {
        case 1:
            return (
                <div className="bg-light p-5 text-center" style={{ backgroundImage: `url(${coverImage})`, backgroundSize: 'cover', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={logoStyle}>{shop.name}</h1>
                </div>
            );
        case 2:
            return (
                <div className='shop-top'>
                    <h1 style={logoStyle}>{shop.name}</h1>
                    <Image src={coverImage} style={{ objectFit: 'cover', height: '100vh', width: '50vw'}} fluid />
                </div>
            );
        case 3:
            return (
                <div className='shop-top'>
                    <Image src={coverImage} style={{ objectFit: 'cover', height: '100vh', width: '50vw'}} fluid />
                    <h1 style={logoStyle}>{shop.name}</h1>
                </div>
            );
        case 4:
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Image src={coverImage} style={{ objectFit: 'cover', height: '100vh', width: '100vw'}} fluid />
                    <h1 style={logoStyle}>{shop.name}</h1>
                </div>
            );
        case 5:
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={logoStyle}>{shop.name}</h1>
                    <Image src={coverImage} style={{ objectFit: 'cover', height: '100vh', width: '100vw'}} fluid />
                </div>
            );
        case 6:
            return (
                <div className='shop-top'>
                    <h1 style={logoStyle}>{shop.name}</h1>
                </div>
            );
        default:
            return null;
    }
};

export default React.memo(ShopHomeTop);
