import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useFinalPaymentCheckMutation, usePaymentFailedMutation } from "../slices/ordersApiSlice";
import { toast } from "react-toastify";
import Message from "./Message";

const StripeCheckoutForm = ({ onSuccess, orderId, paymentIntentId, totalPrice }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [finalCheck] = useFinalPaymentCheckMutation();
  const [paymentFailed] = usePaymentFailedMutation();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("支払いが完了しました。");
          break;
        case "processing":
          setMessage("支払いを処理中です。");
          break;
        case "requires_payment_method":
          setMessage("支払い方法を更新してください。");
          break;
        default:
          setMessage("支払いが失敗しました。");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe.js or Elements not loaded yet");
      return;
    }

    setIsLoading(true);
    console.log("支払いを確認中...");

    try {
      const res = await finalCheck({
        orderId,
        paymentIntentId,
        totalPrice
      }).unwrap();
      toast.success(res.message);
    } catch (error) {
      setMessage(error.data.message);
      setIsLoading(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "https://localhost:3000/profile",
      },
      redirect: "if_required"
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      const res = await paymentFailed(orderId).unwrap();
      toast.error(res.message);
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
        console.log("支払いエラー: ", error.message);
      } else {
        setMessage("支払い中にエラーが発生しました。何度試してもエラーが発生する場合はサポートにお問い合わせください。");
        console.log("支払いエラー: ", error);
      }
    } else {
      // 支払いが成功した場合に onSuccess を呼び出します
      onSuccess();
    }

    setIsLoading(false);
    console.log("支払いが完了しました");
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Button 
        type='submit' 
        className='btn-block mt-3' 
        disabled={isLoading || !stripe || !elements}
      >
        {isLoading ? <div className="spinner" id="spinner"></div> : "支払う"}
      </Button>
      {/* Show any error or success messages */}
      {message && <Message className='mt-3'>{message}</Message>}
    </form>
  );
}

export default StripeCheckoutForm;
